
import Vue, { PropOptions } from 'vue';

export default Vue.extend({
  name: 'StatusCompany',
  props: {
    status: {
      type: String as () => string,
      default: 'notInformed',
    } as PropOptions,
  },
  computed: {
    getStatus(): string {
      if (['ativa', 'ativo'].includes(this.status?.toLowerCase())) {
        return 'active';
      }

      if (this.status === 'notInformed') {
        return 'notInformed';
      }

      return 'inactive';
    },
  },
});
