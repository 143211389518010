import { IntegrationSystem as IntegrationSystemBase } from '@/resources/integrations/types';
import {
  Establishment, TaxInvoiceInbound, TaxInvoiceInboundStatus, TaxRegime,
} from '@roit-intern/roit-company-interfaces';
import { Method as AxiosMethod } from 'axios';
import Vue from 'vue';
import { Store } from 'vuex';

export type IntegrationSystem = IntegrationSystemBase;

export interface CustomStore extends Store<any> {
  state: {
    auth?: Record<string, any>;
  };
}

export interface DigitalCertificate {
  id?: string;
  nationalRegister?: string;
  companyName?: string;
  validate?: string;
  fileName?: string;
  companyId?: string;
  base64?: string;
  senha?: string;
  path?: string;
  status?: string;
  url?: string;
  canSEFAZNoteDownload?: boolean;
  inUse?: boolean;
}

export type VForm = Vue & {
  validate: () => Promise<any>;
  resetValidation: () => boolean;
  reset: () => void;
};

export type FormRef = Vue & {
  submit: () => void;
};

export interface OldIntegrationSystem {
  id?: string;
  sistema: string | null;
  versao: string | null;
  token: string | null;
  usuario: string | null;
  senha: string | null;
  ip: string | null;
  porta: string | null;
  ipPorta: string | null;
  companyDb: string | null;
  finalidade: string[] | null;
  actions?: Array<() => void>;
  retryAfter: string | null;
  companies: string[];
}

interface ManagerUserOther {
  id?: string;
  userId?: string;
  name?: string;
  middleName?: string;
  fullName?: string;
  company?: string;
  cnpj?: string;
}

export interface Employee {
  companyGroups: string[];
  updateAt: Date;
  updateTimestampAt: number;
  id: string;
  productResponsable: boolean;
  createAt: Date;
  createTimestampAt: number;
  master?: string;
  supplier?: string;
  name: string;
  iamId: string;
  status: 'DISABLED' | 'ENABLED';
  profiles: { [key: string]: { id: string; name: string } };
  email: string;
}

export interface ManageUser extends Employee {
  allowedAccessTypes: ('LOGIN_PASS' | 'SSO')[];
}

export interface ManageUserForm {
  firstName: string;
  lastName: string;
  job: string;
  companyName?: string;
  email: string;
  profiles: { [key: string]: { id: string; name: string } };
  responsabilities: string[];
  companyGroups?: string[];
  allowedAccessTypes: ('LOGIN_PASS' | 'SSO')[];
  iamId?: string;
}

export interface RoitFile {
  certificado: {
    name: string;
    file: File;
    base64?: any;
  };
  senha: string;
  readonly?: boolean;
  sent?: boolean;
}

export interface ChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface PasswordRules {
  title: string;
  rule: RegExp;
}

export interface Company {
  id: string;
  cnpj: string;
  name: string;
  city: string;
  state: string;
  statusReceitaFederal: string;
  stateRegistration?: string;
  municipalRegistration?: string;
  taxRegime?: {
    type: string;
    assessment: string;
  };
  idErp?: string;
  filiais?: Array<Company>;
  parentCnpj?: string;
  companyGroup?: string;
  companyGroupId?: string;
  master?: boolean;
  userId?: string;
  hubSpotId?: string;
  address?: AddressCommon;
  nationalRegister: string;
  companyId: string;
  taxGuide: string;
  tradeName: string;
}

enum Status {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface Response {
  message: string;
  status: Status;
  errors?: Array<any>;
  timestamp: number;
}

export interface HttpErrorResponse {
  config: Record<string, any>;
  data: Response;
  headers: Record<string, any>;
  request: Record<string, any>;
}

export interface UpdateCompanyGroup {
  companiesToAdd?: Array<string>;
  companiesToDelete?: Array<string>;
}

export interface Product {
  id: string;
  proprietario: string;
  modulo: string;
  plano: string;
  qtdeContratada: number;
  status: string;
  vigenciaFinal: Date;
  vigenciaInicio: Date;
}

export interface ProductGroup {
  linkContrato?: string;
  products: Array<Product>;
}

export interface CancelProductInformation {
  rating: string;
  comment: string;
  ownerId: string;
  module: string;
}

export interface CertificateFile {
  fileName: string;
  base64: string;
  id: string;
  password: string;
  companyId: string;
  canSEFAZNoteDownload: boolean;
  nationalRegister: string;
}

export type CompanyCertificate = Pick<Company, 'nationalRegister'>;

export interface Certificate extends CompanyCertificate {
  nationalRegister: string;
  files: Array<CertificateFile>;
}

export interface CardManagerInformation {
  name: string;
  cardNumber: string;
  expiresIn: string;
  securityCode: string | number;
  isMain: boolean;
  flag: string;
  showNumber: boolean;
}

export type DateFormat = {
  input: string;
  output: string;
}

export interface HeaderCustomTable {
  alias: Array<string>;
  columnsToShow: Array<string>;
}

export interface RoitProfile extends SelectOption {
  disabled?: boolean;
}

export enum UserAdminProduct {
  BANK = 'ROIT',
  PEOPLE = 'ROIT People'
}

export interface UserAdmin {
  id?: string;
  job: string;
  email: string;
  firstName: string;
  lastName: string;
  products: string[];
}

export interface UserAdminList {
  id: string;
  email: string;
  job: string;
  name: string;
  lastName: string;
  fullName: string;
  userId: string;
  products: string[];
}

export interface CompanyGroup {
  master: string;
  name: string;
  companies: string[];
  id: string;
}

export interface CompaniesGrouped {
  [key: string]: Array<Company>;
}

export interface PaymentMethod {
  id: string;
  userId: string;
  paymentMethods: Array<Method>;
  billing: Billing;
  invoice: Invoice;
}

export interface Method {
  name: string;
  expiresIn: string;
  cardNumber: string;
  flag: string;
  securityCode: string | number;
  isMain: boolean;
  showNumber: boolean;
  vindiId?: number;
}

export interface AddressCommon {
  street: string;
  number: string;
  additionalDetails: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

export interface Billing extends AddressCommon {
  company: string;
  email: string;
}
export interface BillingItem {
  cnpj: string;
  company: string;
  consumed: number;
  contracted: string;
  due_date: string;
  id: number;
  payment_method: string;
  period: BillingPeriod;
  plan: string;
  product: string;
  status: string;
  surplus: number;
  surplus_value: number;
  total_value: string;
  unit_value: string;
}

export interface BillingPeriod {
  billing_at: string;
  cycle: number;
  duration: number;
  end_at: string;
  id: number;
  start_at: string;
}

export interface BillingPaymentCompany {
  id: number;
  name: string;
  code: string;
}

export interface BillingRenewedCard {
  card_number_last_four: string | null;
  card_expiration: string | null;
}

export interface BillingPaymentProfile {
  id: number;
  holder_name: string;
  registry_code: string | null;
  bank_branch: string | null;
  bank_account: string | null;
  card_expiration: string;
  allow_as_fallback: boolean | null;
  card_number_first_six: string;
  card_number_last_four: string;
  renewed_card: BillingRenewedCard;
  card_renewed_at: string | null;
  token: string;
  created_at: string;
  payment_company: BillingPaymentCompany;
}

export interface BillingGateway {
  id: number;
  connector: string;
}

export interface BillingLastTransaction {
  id: number;
  transaction_type: string;
  status: string;
  amount: number;
  installments: number;
  gateway_message: string;
  gateway_response_code: string | null;
  gateway_authorization: string;
  gateway_transaction_id: string;
  gateway_response_fields: string;
  fraud_detector_score: number | null;
  fraud_detector_status: string | null;
  fraud_detector_id: string | null;
  created_at: string;
  gateway: BillingGateway;
  payment_profile: BillingPaymentProfile;
}

export interface BillingPaymentMethod {
  id: number;
  public_name: string;
  name: string;
  code: string;
  type: string;
}
export interface BillingCharge {
  id: number;
  amount: number;
  status: string;
  due_at: string;
  paid_at: string;
  installments: number;
  attempt_count: number;
  next_attempt: number | null;
  print_url: string | null;
  created_at: string;
  updated_at: string;
  last_transaction: BillingLastTransaction;
  payment_method: BillingPaymentMethod;
}
export interface Invoice extends AddressCommon {
  firstName: string;
  lastName: string;
}

export interface State {
  code: number;
  name: string;
  uf: string;
}

export interface City {
  capital: boolean;
  codeIBGE: number;
  latitude: number;
  longitude: number;
  name: string;
  stateCode: number;
}

export interface Password {
  oldPassword: string;
  newPassword: string;
}

export interface Personal {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  photoUrl: string;
  backgroundPhotoUrl: string;
  phoneNumber: string;
  residentialPhoneNumber: string;
  job: string;
}

export interface PersonalProfile extends Personal {
  address: AddressCommon;

}

export interface UserProfile {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  photoUrl: string;
  backgroundPhotoUrl: string;
  phoneNumber: string;
  residentialPhoneNumber: string;
  job: string;
  address: AddressCommon;
  credentials: Password;
}

export interface CompanyProfile extends Company {
  address: AddressCommon;
}

export interface Profile {
  user: UserProfile;
  company: CompanyProfile;
}

export interface BillProduct {
  id: number;
  name: string;
  code: string;
}
export interface PricingRange {
  id: number;
  start_quantity: number;
  end_quantity: number;
  price: number;
  overage_price: number;
}
export interface PricingSchema {
  id: number;
  short_format: string;
  price: number;
  minimum_price: number;
  schema_type: string;
  pricing_ranges: PricingRange[];
  created_at: string;
}
export interface BillProducts {
  id: number;
  amount: number;
  pricing_schema: PricingSchema;
  product: BillProduct;
}

export interface I18nFlagType {
  flag: string;
  language: string;
  title: string;
}

export interface UserEmployee {
  name: string;
  master: string;
  companyGroups: EmployeeCompanyGroup[];
  clientName: string;
  clientId: string;
  clientInfo: ClientInfo;
}

export interface ClientInfo {
  name: string;
  id: string;
}

export interface EmployeeCompanyGroup {
  id: string;
  master: string;
  name: string;
  companies: EmployeeCompany[];
}

export interface EmployeeCompany {
  id: string;
  companyGroupId: string;
  companyId: string;
  name: string;
  companyName: string;
  legalNature: string;
  legalNatureCode: string;
  legalNatureDescription: string;
  meiOption: boolean;
  nationalRegister: string;
  responsibleQualification: string;
  shareCompany: string;
  simplesExcludeDate: string;
  simplesOption: boolean;
  attDate: string;
  simplesOptionDate: string;
  size: string;
  tradeName: string;
  taxRegime: EmployeeCompanyTaxRegime;
  establishments: EmployeeEstablishment[];
}

export interface EmployeeCompanyTaxRegime {
  assessment: string;
  type: string;
}

export interface EmployeeEstablishment {
  id: string;
  companyId: string;
  name: string;
  companyName: string;
  nationalRegister: string;
  headOffice: boolean;
  branchNumber: number;
  identifier: string;
  additionalInfo: string;
  contact: EmployeeEstablishmentContact;
}

export interface EmployeeEstablishmentContact {
  email: string;
  phone: string;
}

export interface EstablishmentsGrouped {
  groupName: string;
  groupId: string;
  establishments: CompanyGroupsEstablishment[];
}

export interface CompanyGroupsEstablishment extends Establishment {
  company?: Company;
  filiais?: Establishment[];
}
export interface AddTaxRegime {
  companyId: string;
  taxRegime: TaxRegime;
}

export interface RoitApiResponse<T> {
  data: T;
  status: string;
  timestamp: number;
}

export type SelectOption = {
  optionLabel: string;
  optionValue: string;
  optionDisabled?: boolean;
  // deprecated
  name?: string;
}

export interface AddCompanyPayload {
  cnpj: string;
}

export interface AddTaxGuide {
  companyId: string;
  taxGuide: TaxGuide;
}

enum TaxGuide {
  document = 'document',
  provider = 'provider',
  tax = 'tax'
}
export interface PatchCompanyGroup {
  id: string;
  companyGroupIds: string[];
}

export interface DisableEmployee {
  email: string;
}

export interface EnableEmployee {
  email: string;
  profiles: Profile[];
}

export enum LegalSituation {
  ACTIVE = 'ATIVA',
  INVOICEDED = 'BAIXADA',
  INAPT = 'INAPTA',
  INACTIVE = 'INATIVA',
  NULL = 'NULA',
  SUSPENDED = 'SUSPENSA',
}
export interface Branch {
  id: string;
  name: string;
  type: string;
  nationalRegister: string;
  address: {
    city: string;
    state: string;
    ibgeCode: number;
    cityCode: number;
  };
  companyName: string;
  companyId: string;
  fileName?: string;
  certificateId?: string;
  taxInvoiceInbound?: TaxInvoiceInbound[];
  taxInvoiceInboundStatus?: TaxInvoiceInboundStatus;
  status: string;
  downloadAutomaticXml?: boolean;
  identifier: string;
  municipalRegistration: string;
  legalSituation: LegalSituation;
}

export interface EstablishmentTaxInvoiceInbound extends Branch {
  filiais: Branch[];
}

export interface Group {
  groupName: string;
  establishments: EstablishmentTaxInvoiceInbound[];
}

export interface TaxInvoiceInboundPayload {
  taxInvoiceInbound: TaxInvoiceInbound[];
  identifier: string;
  downloadAutomaticXml: boolean;
  nationalRegister: string;
  certificateId: string;
  municipalRegistration: string;
}
export interface CompanyPreferences {
  clientId: string;
  emailInbox: string[];
}
export interface CreateCertificateAlert {
  alertWithDays: number;
  masterId: string;
  recipient: string[];
}

export interface UpdateCertificateAlert {
  alertWithDays?: number;
  masterId: string;
  recipient?: string[];
}
export interface ENotasCompanyNFSeSettingsDTO {
  sequentialNFe: number;
  seriesNFe: string;
  sequentialBatchNFe: number;
  userAccessProvider?: string;
  passwordAccessProvider?: string;
  tokenAccessProvider?: string;
}
export interface ENotasCompanyAddressDTO {
  ibgeUfCode: number;
  ibgeCityCode: number;
  state: string;
  city: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  zipCode: string;
}

export interface ENotasCreateOrUpdateDTO {
  id?: string;
  identifier: string;
  municipalRegistration?: string;
  stateRegistration?: string;
  companyName?: string;
  tradeName?: string;
  nationalSimpleOpting?: boolean;
  cultureIncentive?: boolean;
  address?: ENotasCompanyAddressDTO;
  specialTaxationRegime?: string;
  municipalServiceCode?: string;
  cnae?: string;
  issRate?: number;
  descriptionService?: string;
  nfseHomologSettings?: ENotasCompanyNFSeSettingsDTO;
  nfseProductionSettings?: ENotasCompanyNFSeSettingsDTO;
}
export interface UpdateCertificatesInBatchPayload {
  nationalRegister: string;
  certificateId: string;
  fileName: string;
}

export interface TaskRequest {
  url: string;
  service: string;
  method: AxiosMethod;
  body?: Record<string, string>;
  params?: Record<string, string>;
  header: Record<string, string>;
}

interface ConfigScheduler {
  minute: string;
  hour: string;
  dayMonth: string;
  month: string;
  dayWeek: string;
  cron: string;
}

export enum EnumConfigSchedulerStatus {
  Enabled = 'Enabled',
  Paused = 'Paused'
}
export interface TaskScheduler {
  id: string;
  service: string;
  masterId: string;
  region: string;
  status: EnumConfigSchedulerStatus;
  description: string;
  config: ConfigScheduler;
  request: TaskRequest;
}

export interface TaskSchedulerCreate {
  service: string;
  masterId: string;
  config?: ConfigScheduler;
}

export interface TaskSchedulerPayloadMetadata extends TaskSchedulerCreate {
  isValid?: boolean;
}

export enum EnumTaskManagerRadioButtons {
  EveryHours = 'everyHours',
  EveryXHours = 'everyXHours',
  SpecificHour = 'specificHour',
  EveryDaysOfWeek = 'everyDaysOfWeek',
  EveryDaysOfMonth = 'everyDaysOfMonth',
  EveryXDaysOfMonth = 'everyXDaysOfMonth',
  EveryMonths = 'everyMonths',
  EveryXMounths = 'everyXMounths',
  SelectMonths = 'selectMonths',
  Interval = 'interval',
  SelectDaysOfMonth = 'selectDaysOfMonth',
  SelectDaysOfWeek = 'selectDaysOfWeek',
  EveryXMinutes = 'everyXMinutes',
}

export interface SchedulesStatusLogs {
  schedulerId: string;
  statusCode: string;
  message: string;
  executedAt: string;
  service: string;
}

export interface CaptionInterface {
  type: 'success' | 'warning' | 'danger' | 'info';
  label: string;
}

export interface MunicipalRegistrationChanged {
  id: string;
  municipalRegistration: string;
}

export enum Orientation {
  PREVIOUS_PAGE = 'previousPage',
  NEXT_PAGE = 'nextPage',
  LAST_PAGE = 'lastPage'
}
export interface PaginationLimitDTO {
  limit?: string;
  cursor?: string;
  orientation?: Orientation;

}
export interface EstablishmentWithBranchs extends Establishment {
  branchs: Establishment[];
}

export interface EstablishmentsCompany extends CompanyGroup {
  establishments: EstablishmentWithBranchs[];
}

export interface Pagination {
  page: number;
  perPage: number;
}

export interface Serializer {
  serializer: string;
}

export type PaginationSerializer = Partial<Pagination> & Partial<Serializer>

export interface PaginationMetadata {
  totalPages: string;
  previousPage: string;
  currentPage: string;
  nextPage: string;
  totalItens: number;
}
