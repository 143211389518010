var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "company-status",
      class: `company-status--${_vm.getStatus}`,
    },
    [
      _c("div", {
        staticClass: "company-status__circle",
        class: `company-status__circle--${_vm.getStatus}`,
      }),
      _c(
        "p",
        {
          staticClass: "company-status__text",
          class: `company-status__text--${_vm.getStatus}`,
        },
        [_vm._v(" " + _vm._s(_vm.$t(`${_vm.getStatus}`)) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }